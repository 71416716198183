var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import EE from '@antv/event-emitter';
import { ChartEvent } from '@antv/g2';
import { Chart } from './chart';
import { Controller as Annotaion } from '../annotation';
import { CHART_OPTIONS, SKIP_DEL_CUSTOM_SIGN } from '../constants';
import { mergeWithArrayCoverage, pick } from '../utils';
var SOURCE_ATTRIBUTE_NAME = 'data-chart-source-type';
var Plot = /** @class */ (function (_super) {
    __extends(Plot, _super);
    function Plot(container, options) {
        var _this = _super.call(this) || this;
        _this.container = typeof container === 'string' ? document.getElementById(container) : container;
        _this.options = mergeWithArrayCoverage({}, _this.getBaseOptions(), _this.getDefaultOptions(), options);
        _this.createG2();
        _this.bindEvents();
        return _this;
    }
    /**
     * new Chart 所需配置
     */
    Plot.prototype.getChartOptions = function () {
        return __assign(__assign({}, pick(this.options, CHART_OPTIONS)), { container: this.container });
    };
    /**
     * G2 options(Spec) 配置
     */
    Plot.prototype.getSpecOptions = function () {
        if (this.type === 'base' || this[SKIP_DEL_CUSTOM_SIGN]) {
            return __assign(__assign({}, this.options), this.getChartOptions());
        }
        return this.options;
    };
    /**
     * 创建 G2 实例
     */
    Plot.prototype.createG2 = function () {
        if (!this.container) {
            throw Error('The container is not initialized!');
        }
        this.chart = new Chart(this.getChartOptions());
        // 给容器增加标识，知道图表的来源区别于 G2
        this.container.setAttribute(SOURCE_ATTRIBUTE_NAME, 'Ant Design Charts');
    };
    /**
     * 绑定代理所有 G2 的事件
     */
    Plot.prototype.bindEvents = function () {
        var _this = this;
        if (this.chart) {
            this.chart.on('*', function (e) {
                if (e === null || e === void 0 ? void 0 : e.type) {
                    _this.emit(e.type, e);
                }
            });
        }
    };
    Plot.prototype.getBaseOptions = function () {
        return { type: 'view', autoFit: true };
    };
    /**
     * 获取默认的 options 配置项，每个组件都可以复写
     */
    Plot.prototype.getDefaultOptions = function () { };
    /**
     * 绘制
     */
    Plot.prototype.render = function () {
        var _this = this;
        // 执行 adaptor , base 穿透类型不必 adaptor.
        if (this.type !== 'base') {
            this.execAdaptor();
        }
        // options 转换
        this.chart.options(this.getSpecOptions());
        // 渲染
        this.chart.render().then(function () {
            _this.annotation = new Annotaion(_this.chart, _this.options);
        });
        // 绑定
        this.bindSizeSensor();
    };
    /**
     * 更新
     * @param options
     */
    Plot.prototype.update = function (options) {
        this.updateOption(options);
    };
    /**
     * 更新配置
     * @param options
     */
    Plot.prototype.updateOption = function (options) {
        this.options = mergeWithArrayCoverage({}, this.options, options);
    };
    /**
     * 更新数据
     * @override
     * @param options
     */
    Plot.prototype.changeData = function (data) {
        this.chart.changeData(data);
    };
    /**
     * 修改画布大小
     * @param width
     * @param height
     */
    Plot.prototype.changeSize = function (width, height) {
        this.chart.changeSize(width, height);
    };
    /**
     * 销毁
     */
    Plot.prototype.destroy = function () {
        // G2 的销毁
        this.chart.destroy();
        // 清空已经绑定的事件
        this.off();
        this.container.removeAttribute(SOURCE_ATTRIBUTE_NAME);
    };
    /**
     * 执行 adaptor 操作
     */
    Plot.prototype.execAdaptor = function () {
        var adaptor = this.getSchemaAdaptor();
        // 转化成 G2 Spec
        adaptor({
            chart: this.chart,
            options: this.options,
        });
    };
    /**
     * 当图表容器大小变化的时候，执行的函数
     */
    Plot.prototype.triggerResize = function () {
        this.chart.forceFit();
    };
    /**
     * 绑定 dom 容器大小变化的事件
     */
    Plot.prototype.bindSizeSensor = function () {
        var _this = this;
        var _a = this.options.autoFit, autoFit = _a === void 0 ? true : _a;
        if (autoFit) {
            this.chart.on(ChartEvent.AFTER_CHANGE_SIZE, function () {
                _this.annotation.update();
            });
        }
    };
    return Plot;
}(EE));
export { Plot };
