var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { set, pick, get } from '../utils';
import { COORDIANTE_OPTIONS } from '../constants';
/**
 * 主要为极坐标配置， 饼图、雷达图等
 * 通用坐标系配置, coordinate 配置根据图表默认进行配置，例如 coordinate 饼图 { type: 'theta' } 玉珏图 { type: 'radial' }。
 * 也可直接通过 options.coordinate 进行穿透。
 * 同样 coordinate 但其他配置需要直接在 options 中配置，例如 主要配置内径 innerRadius 结束角度 endAngle 等, 次要配置 focusX distortionX。
 * @param params Adaptor
 * @returns params Adaptor
 */
export function coordinate(params) {
    var options = params.options;
    var _a = options.coordinate, coordinate = _a === void 0 ? {} : _a;
    set(params, ['options', 'coordinate'], __assign(__assign(__assign({}, coordinate), pick(options, COORDIANTE_OPTIONS)), { outerRadius: get(options, 'radius') }));
    return params;
}
